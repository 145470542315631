// js для свайпера, подключается блоками
// подробнее тут <https://swiperjs.com/get-started>
import SwiperCore, { Swiper, Pagination, Navigation, Autoplay, Lazy } from "swiper/core";
SwiperCore.use([Swiper, Pagination, Navigation, Autoplay, Lazy]);

// стили для свайпера
// брать тут <https://swiperjs.com/swiper-api#styles>, только для нужных компонентов
require("swiper/swiper.min.css");
require("swiper/components/navigation/navigation.min.css");
require("swiper/components/pagination/pagination.min.css");

let point = window.matchMedia('(min-width:768px) and (max-width: 800px)');
let actionSlider;

window.addEventListener("DOMContentLoaded", () => {
    // ########################################################
    // index-new slider
    if (document.querySelector("[data-swiper-index-new]")) {
        new Swiper("[data-swiper-index-new]", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: false,
            },
            spaceBetween: 0,

            // Скорость переключения слайдов
            speed: 1000,

            // Автопрокрутка
            autoplay: {
                // Задержка
                delay: 3000,
                // 	// Закончить на последнем слайде
                // 	stopOnLastSlide: true,
                // откючить после юзера
                disableOnInteraction: false,
            },

            // Пагинация
            pagination: {
                el: "[data-swiper-index-new-pagination]",
                type: "bullets",
                clickable: true,
            },
        });
    }

    // ########################################################
    // index-brands slider
    if (document.querySelector(".swiper-container-index-brands")) {
        new Swiper(".swiper-container-index-brands", {

            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            // Убирает {pagination} если нет переполнения для прокрутки
            watchOverflow: true,
            pagination: {
                el: ".swiper-pagination",
                // Буллеты
                clickable: true,
                type: "bullets",
            },
            slidesPerColumnFill: 'row',
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                320: {
                    // Отступ между слайдами
                    spaceBetween: 15,
                    slidesPerView: 2,
                    slidesPerColumn: 3,
                },
                768: {
                    // Отступ между слайдами
                    spaceBetween: 20,
                    slidesPerView: 3,
                    slidesPerColumn: 3,
                },
                992: {
                    // Отступ между слайдами
                    spaceBetween: 25,
                    slidesPerView: 4,
                    slidesPerColumn: 8,
                },
                1200: {
                    // Отступ между слайдами
                    spaceBetween: 30,
                    slidesPerView: 6,
                    slidesPerColumn: 8,
                },
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: false,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
        });
    }

    // ########################################################
    // index-news slider
    if (document.querySelector(".swiper-container-index-news")) {
        new Swiper(".swiper-container-index-news", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            // =======================================================================
            // Навигация
            navigation: {
                prevEl: ".pagination__arrow-prev",
                nextEl: ".pagination__arrow-next",
            },

            // Стрелки, выводятся в :after

            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                },
                1140: {
                    slidesPerView: 3,
                    watchOverflow: true,
                    spaceBetween: 30,
                },
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
        });
    }

    // ########################################################
    // advantages slider
    if (document.querySelector(".swiper-container-advantages")) {
        new Swiper(".swiper-container-advantages", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            // Мобайл-first подход (min-width: ...)
            watchOverflow: true,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    slidesPerColumn: 2,
                    spaceBetween: 20,
                },
                600: {
                    slidesPerView: 2,
                    slidesPerColumn: 2,
                    spaceBetween: 85,
                },
                900: {
                    slidesPerView: 3,
                    slidesPerColumn: 2,
                    spaceBetween: 60,
                },
                1200: {
                    slidesPerView: 4,
                    slidesPerColumn: 2,
                    spaceBetween: 20,
                },
            },

            slidesPerColumnFill: "row",
            // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
            // ========================================================
            pagination: {
                el: ".swiper-pagination-advantages",
                type: "bullets",
            },
        });
    }

    // ########################################################
    // action slider
    if (document.querySelector(".swiper-container-action")) {
        actionSlider = new Swiper(".swiper-container-action", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            // Мобайл-first подход (min-width: ...)
            watchOverflow: true,
            breakpoints: {
                0: {
                    spaceBetween: 20,
                    slidesPerView: 1,
                    noSwiping: false,
                    allowTouchMove: true,
                },
                768: {
                    spaceBetween: 0,
                    noSwiping: true,
                    allowTouchMove: false,
                }
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            // Навигация
            navigation: {
                prevEl: ".action__pagination .pagination__arrow-prev",
                nextEl: ".action__pagination .pagination__arrow-next",
            },
        });

        window.addEventListener('resize', () => {
            if(point.matches) {
                actionSlider.slideTo(0);
            }
        })
    }

    // ########################################################
    // action slider
    if (document.querySelector(".swiper-container-about")) {
        new Swiper(".swiper-container-about", {

            // Отступ между слайдами
            spaceBetween: 20,

            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            centerSlides: true,

            watchOverflow: true,
            slidesPerColumnFill: 'row',
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                500: {
                    slidesPerView: 2,
                },
                700: {
                    slidesPerView: 3,
                    autoHeight: false,
                    // Не работает с loop
                    slidesPerColumn: 2,
                },
                950: {
                    slidesPerView: 4,
                    slidesPerColumn: 2,
                },
                1200: {
                    slidesPerView: 3,
                    slidesPerColumn: 2,
                },
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            // Пагинация
            pagination: {
                el: ".swiper-pagination",
                type: 'bullets',
                clickable: true,
            },
        });
    }

    // ########################################################
    // black-list slider
    if (document.querySelector(".swiper-container-black-list")) {
        new Swiper(".swiper-container-black-list", {

            // Отступ между слайдами
            spaceBetween: 20,

            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            centerSlides: true,

            watchOverflow: true,
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                320: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1140: {
                    slidesPerView: 6,
                },
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
            // Пагинация
            pagination: {
                el: ".swiper-pagination",
                type: 'bullets',
                clickable: true,
            },
        });
    }

    // ########################################################
    // service slider
    if (document.querySelector(".swiper-container-service")) {
        new Swiper(".swiper-container-service", {

            // Отступ между слайдами
            spaceBetween: 20,

            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            watchOverflow: true,

            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1140: {
                    slidesPerView: 4,
                },
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },
            // Скорость переключения слайдов
            speed: 700,

            // Автопрокрутка
            // autoplay: {
            //     // Задержка
            //     delay: 3000,
            //     // Закончить на последнем слайде
            //     stopOnLastSlide: true,
            //     // откючить после юзера
            //     disableOnInteraction: false,
            // },

            // Навигация
            navigation: {
                prevEl: ".pagination__arrow.pagination__arrow-prev",
                nextEl: ".pagination__arrow.pagination__arrow-next",
            },
        });
    }

    // ########################################################
    // service-support slider
    if (document.querySelector(".swiper-container-service-support")) {
        new Swiper(".swiper-container-service-support", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            loop: true,
            // Пагинация
            pagination: {
                el: ".swiper-pagination",
                type: 'bullets',
                clickable: true,
            },
        });
    }
    // ########################################################
    // service-support slider
    if (document.querySelector("[data-swiper-b-tech]")) {
        new Swiper("[data-swiper-b-tech]", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            spaceBetween: 20,

            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },
            slidesPerColumnFill: 'row',
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                },
                768: {
                    slidesPerView: 2,
                    slidesPerColumn: 2,
                },
                1440: {
                    slidesPerView: 4,
                    slidesPerColumn: 1,
                },
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            // loop: true,
            // Пагинация
            pagination: {
                el: ".b-tech-swiper__pagination",
                type: 'bullets',
                clickable: true,
            },
        });
    }
    // ########################################################
    // data-swiper-en-slider slider
    if (document.querySelector("[data-swiper-en-slider]")) {
        new Swiper("[data-swiper-en-slider]", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            // Включить предзагрузку изображений
            preloadImages: false,
            // отключить при переполнении
            watchOverflow: true,
            // // Ленивая загрузка
            // lazy: {
            //     // Начало загрузки при свайпе
            //     loadOnTransitionStart: true,
            //     // Подгружать prev/next картинки
            //     loadPrevNext: true,
            // },
            // slidesPerColumnFill: 'row',
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    // slidesPerColumn: 1,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    // slidesPerColumn: 2,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                    // slidesPerColumn: 1,
                },
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            // loop: true,
            // // Пагинация
            // pagination: {
            //     el: ".b-tech-swiper__pagination",
            //     type: 'bullets',
            //     clickable: true,
            // },
            navigation: {
                prevEl: '[data-swiper-en-prev]',
                nextEl: '[data-swiper-en-next]',
            }
        });
    }
});
