/**
 *  Можно указать селектор к которому применится класс 'success' после успешной обработки формы
 *  По-умолчанию класс 'success' добавится непосредственно форме
 *
 *  data-success-selector=".success-holder"
 *
 *  <form action="{% url 'namespace:route' %}" method="post" data-ajax-form="ContactForm">
 *      ...
 *  </form>
 */

import {
    validatorValidateForm,
    validatorCleanErrors,
} from "../../components/forms/validation";
import LiveEvent from "../../components/live/live";
import Jax from "../../components/jax/jax";
import {longText} from "../blocks/_longText";

document.addEventListener("DOMContentLoaded", function(){
    const all_forms = document.querySelectorAll(['[data-ajax-form]']);
    all_forms.forEach((form, key) => {
        const button = form.querySelector("button");
        button.disabled = false;
    })
});

new LiveEvent("submit", "[data-ajax-form]", function submitAjax(e) {
    e.preventDefault();
    const current_form = this;
    const classes = this.dataset.ajaxForm.split(",");
    const successSelector = this.dataset.successSelector;
    const success = successSelector
        ? document.querySelector(successSelector)
        : this;
    const successTrigger = this.dataset.successTrigger;

    const button = this.querySelector("button");
    const button_text = button.innerHTML;
    button.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i>';
    button.disabled = true;

    const jax = new Jax(
        this.getAttribute("action"),
        this.getAttribute("method"),
        true
    );

    jax.send(new FormData(this)).then((data, xhr) => {
        button.innerHTML = button_text;
        button.disabled = false;

        let errorsList = {};
        if (data.errors) {
            errorsList = data.errors;
            current_form.scrollIntoView({behavior: 'smooth'});
        }
        Object.keys(classes).forEach((i) => {
            const cls = classes[i];
            if (errorsList[cls]) {
                validatorValidateForm(this, cls, errorsList[cls]);
            } else {
                validatorCleanErrors(this, cls);
            }
        });
        if (data.state === "success") {
            success.classList.add("success");
            if (successTrigger) {
                document.dispatchEvent(
                    new CustomEvent("ajax-form-success", {
                        detail: { form: this },
                    })
                );
            }
            if (this.dataset.goal) {
                window.goal(this.dataset.goal);
            }
            if (data.redirect) {
                window.location.replace(data.redirect);
            }
            const clearTimeout = this.getAttribute("data-clear-timeout");
            if (clearTimeout) {
                setTimeout(() => {
                    this.reset();
                    document.dispatchEvent(
                        new CustomEvent("success", { detail: { form: this } })
                    );
                    success.classList.remove("success");
                }, clearTimeout);
            }
        }
    });
});

/**
 Пример action:

 public function contact()
 {
     if (!$this->request->getIsAjax() || !$this->request->getIsPost()) {
            $this->error(404);
        }
        $form = new RequestForm();
        $data = [
            'state' => 'success'
        ];
        if (!($form->fill($_POST) && $form->valid && $form->send())) {
            $data = [
                'state' => 'error',
                'errors' => [
                    $form->classNameShort() => $form->getErrors()
                ]
            ];
        }
        echo json_encode($data);
 }

 */
