"use strict";

import { animationNum } from "./_animNumber";
import { filterParamGet } from "./_filterUrl";
import { Tab } from "./_initTabs";
import { liveSort } from "./_liveSort";
import { longText } from "./_longText";
import { showHide } from "./_showHide";

window.addEventListener("DOMContentLoaded", () => {
    new Tab('.vacancy__btn', '.vacancy__description', {
        parentSelector: document,
        activeNumber: 0,
        useClass: '_active',
        classListLikeBem: true,
    })
    liveSort(".filter__search-brand", ".filter__brand-box");
    liveSort(".filter__search-sity", ".filter__sity-box");
    showHide("filter__search-sity", "filter__result-sity");
    showHide("filter__search-brand", "filter__result-brand", true);

    filterParamGet('[data-url]')
    // Анимирование "номеров" на главной
    animationNum()

    const longTextElements = document.querySelectorAll('[data-long-text]')
    longTextElements?.forEach((el, key) => {
        new longText(el)
    })
});

window.addEventListener('load', () => {
    const parents = document.querySelectorAll('[data-item-box]')
    parents.forEach(parent => setItemHeight(parent, [
        '[data-item="0"]',
        '[data-item="1"]',
    ]))
})

require("./index/index");
require("./_video")


function setItemHeight(parent, items) {

    for (let i = 0; i < items.length; i++) {
        let elements = parent.querySelectorAll(items[i])
        let itemHeight = Math.max(...getItemHeight(elements))
        setItemHeight(elements, itemHeight)
    }

    function setItemHeight(elements, height) {
        for (const element of elements) {
            element.style.height = height + 'px'
        }
    }

    function getItemHeight(arr) {
        let res = [];

        for (const el of arr) {
            res.push(el.offsetHeight)
        }

        return res;
    }
}
