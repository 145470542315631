window.addEventListener("DOMContentLoaded", () => {
    toggleSelect();
});

export function toggleSelect() {
    const selectBtn = document.querySelector(".info__select");
    const selectBody = document.querySelector(".info__body");

    if (selectBtn && selectBody) {

        document.addEventListener("click", (e) => {
            if (!e.target.classList.contains("info__select")) {
                selectBtn.classList.remove("info__select_active");
                selectBody.classList.remove("info__body_active");
            } else {
                selectBtn.classList.toggle("info__select_active");
                selectBody.classList.toggle("info__body_active");
            }
        });

        let inputs = selectBody.querySelectorAll("input");
        // data-default выбран по умолчанию
        selectBtn.textContent = selectBody.querySelector('[data-default]').value;

        inputs.forEach((input) => {
            input.addEventListener("click", () => {
                if (input.value == selectBtn.textContent) {
                    return;
                } else {
                    selectBtn.textContent = input.value;
                }
            });
        });

    }

}
