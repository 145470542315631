export class longText {
    constructor(item, maxLength = 80) {
        this.item = item,
        this.maxLength = maxLength
        this.text = this.item.textContent.trim()
        this.span = document.createElement('span')
        this.showedText = null
        this.hiddenText = null
        this.space = ''
        this.btn = this.createBtn('. . .')
        // this.dots = '…'

        this.init()
    }

    init() {
        if(this.text.length < this.maxLength) {
            return false;
        }
        this.prepareText()
        this.clearItem()
        this.item.insertAdjacentElement('beforeend', this.span)
        this.setText(this.showedText + this.space)
        this.item.insertAdjacentElement('beforeend', this.btn)

        this.item.style.cursor = 'pointer'
        this.item.addEventListener('click', this.showText.bind(this), {once: true})
    }

    showText() {
        // this.setText(this.removeDots(this.showedText) + this.hiddenText)
        this.setText(this.showedText.slice(0 ,this.showedText.length) + this.hiddenText)
        this.item.style.cursor = 'unset'
        this.btn.remove()
    }

    clearItem() {
        this.item.textContent = ''
    }

    setText(text) {
        this.span.textContent = text
    }

    prepareText() {
        // this.showedText = this.addDots(this.text.slice(0, this.maxLength))

        // const dotPos = this.text.indexOf('.')

        // if(dotPos !== -1) {
        //     this.showedText = this.text.slice(0, dotPos)
        //     this.hiddenText = this.text.slice(dotPos)
        // } else {
            this.showedText = this.text.slice(0, this.maxLength)
            this.hiddenText = this.text.slice(this.maxLength)
        // }

        if(this.showedText[this.showedText.length] != ' ') {
            this.space = ' '
        }
    }

    // addDots(text) {
    //     return text += this.dots
    // }
    // removeDots(text) {
    //     return text.slice(0, text.length - this.dots.length)
    // }

    createBtn(text) {
        const btn = document.createElement('button')
        btn.classList.add('show-hide-btn')
        btn.textContent = text
        // btn.addEventListener('click', this.showText.bind(this), {once: true})

        return btn
    }
}


