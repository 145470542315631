import LiveEvent from "../../components/live/live";

/**
 * Created by MooWPro on 13/11/2017.
 */
let placemarks = [];
let geocollection = {};

window.addEventListener('DOMContentLoaded', () => {
    if(typeof ymaps !== 'undefined') {
        ymaps.ready(init);
    }
})

function init() {
    const selector = document.getElementById('map');

    if(selector) start(selector)

    function start(selector) {
        const selects = document.querySelectorAll('[data-shops-select]')
        let mapSelect = null;

        for (const el of selects) {
            if(el.dataset.shopsSelect === 'city') {
                mapSelect = el
                continue
            }
            selectUrl(el)
        }

        const myMap = new ymaps.Map(selector, {
            center: [55.752411, 37.609407],
            zoom: 10,
            controls: ['fullscreenControl','zoomControl']
        }, {
            searchControlProvider: 'yandex#search'
        });

        myMap.behaviors.disable('scrollZoom');


        // on select change
        mapSelect.addEventListener('change', () => {
            const slug = mapSelect.querySelector(`option[value='${mapSelect.value}']`).dataset.slug
            if(mapSelect.dataset.isService==='true'){
                window.location.href=`/service/${slug}`;
            }else{
                window.location.href=`/store/${slug}`;

            }
        })

        function createMarkByCityId(){
            // return value selected option as number
            const id = mapSelect.selectedOptions[0].value

            placemarks = [];
            for (let i = 0, l = groups.length; i < l; i++) {
                if(groups[i].id == id) {
                    createGroup(groups[i]);
                }
            }
        }
        createMarkByCityId();

        function createGroup (group) {
            geocollection = new ymaps.GeoObjectCollection(null, { preset: 'islands#blueIcon' });
            myMap.geoObjects.add(geocollection);
            for (let j = 0, m = group.items.length; j < m; j++) {
                let placemark = new ymaps.Placemark(group.items[j].center, { balloonContent: group.items[j].name });
                geocollection.add(placemark);
                placemarks.push({id: group.items[j].id, mark: placemark});
            }
        }

        if(groups[0].items.length <= 1){
            myMap.setCenter([groups[0].items[0].center[0], groups[0].items[0].center[1]],10);
        }else{
            myMap.setBounds(myMap.geoObjects.getBounds());
        }
    }

    new LiveEvent('click', '[data-balloon-link]', function shopScrollBalloon(e) {
        e.preventDefault();
        const shop_id = this.dataset.shopId
        for (let i = 0, l = placemarks.length; i < l; i++) {
            if(placemarks[i].id == shop_id) {
                placemarks[i].mark.balloon.open();
            }
        }

        const offset = this.dataset.offset || 0;
        if (selector) {
            const viewportOffset = selector.getBoundingClientRect();
            const top = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
            window.scrollTo({
                top: top + viewportOffset.top + offset,
                behavior: 'smooth',
            })
        }

        return false;
    })
}


function selectUrl(select) {
    const name = select.dataset.shopsSelect

    select.addEventListener('change', (e) => {
        // Получаем существующие парамерты
        let currentUrl = new URL(window.location).searchParams
        let newUrl = currentUrl
        // Проверяем, и заменяем парамерты если были
        if(currentUrl !== null || currentUrl !== '') {
            newUrl = replaceUrl(currentUrl)
        }

        newUrl.append(name, e.target.value)

        const newPath = window.location.origin + window.location.pathname + '?' + newUrl.toString()
        if(window.location.href != newPath) window.location.href = newPath
    })

    function replaceUrl(currentUrl) {
        // Приводим к строке
        currentUrl = decodeURI(currentUrl.toString())
        let res = currentUrl

        // ищем уже существующие значения и заменяем на ""
        const matchs = [...currentUrl.matchAll(new RegExp(`${name}=[0-9]+`, 'g'))]

        // Избавляемся от текущих параметров которые будем менять
        for (let i = 0; i < 2; i++) {
            res = res.replace(matchs[i], '')
        }

        return new URLSearchParams(res)
    }
}
