export function animationNum() {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                decoratorOutNum(outNum, entry.target)()
            }
        })
    }, { threshold: 1 })


    const numbers = document.querySelectorAll('[data-anim-num]')

    numbers?.forEach(number => {
        observer.observe(number)
    })


    function decoratorOutNum(func, ...args) {
        return function () {
            func.call(this, ...args)
            observer.unobserve(args[0]);
        }
    }

    function outNum(elem, step = 1, time = 1000) {
        let endValue = elem.textContent
        let value = 0;
        let t = Math.round(time / (endValue / step));

        let interval = setInterval(() => {
            value = value + step;

            if (value == endValue) {
                clearInterval(interval);
            }

            elem.innerHTML = value;
        }, t);
    }
}

