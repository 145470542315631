import { DynamicAdapt } from "./_dynamicAdaptiv";

window.addEventListener("DOMContentLoaded", () => {
    initMenu();
    footerMenu();
    showHeaderSearch();
    const da = new DynamicAdapt("max");
    da.init();
});

export function initMenu() {
    const menu = document.querySelector(".menu");
    const menuList = document.querySelector(".menu__list");
    const menuBurger = document.querySelector(".menu__burger");
    const body = document.body;

    if(menuBurger) {
        menuBurger.addEventListener("click", () => {
            body.classList.toggle("lock");
            menu.classList.toggle("menu_active");
            menuList.classList.toggle("menu__list_active");
            menuBurger.classList.toggle("menu__burger_active");
        });
        window.addEventListener("resize", () => {
            const w = document.documentElement.clientWidth;
            if (w > 1139) {
                body.classList.remove("lock");
                menu.classList.remove("menu_active");
                menuList.classList.remove("menu__list_active");
                menuBurger.classList.remove("menu__burger_active");
            }
        });
    }
}

function footerMenu() {
    const menuBtn = document.querySelector(".footer__menu-btn");
    const menuUl = document.querySelector(".footer__list");
    if(menuBtn && menuUl) {
        menuBtn.addEventListener("click", () => {
            menuBtn.classList.toggle("footer__menu-btn_active");
            menuUl.classList.toggle("footer__list_active");
        });
    }
}

function showHeaderSearch() {
    const btn = document.querySelector(".bottom-header__search-btn");
    const box = document.querySelector(".bottom-header__search-box");

    if(btn) {
        btn.addEventListener("click", () => {
            btn.parentNode.classList.toggle("bottom-header__search_active");
            box.classList.toggle("bottom-header__search-box_active");
        });
    }
}
