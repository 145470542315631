// Функция ymaps.ready() будет вызвана, когда
// загрузятся все компоненты API, а также когда будет готово DOM-дерево.
window.addEventListener('load', () => {
    if(typeof ymaps !== 'undefined') {
        ymaps.ready(init);
    }
})

function init() {
    const contactsMap = document.getElementById('map-contact')

    if(contactsMap) {
        // Создание карты.
        const mapContacts = new ymaps.Map(contactsMap, {
            // Координаты центра карты (широта, долгота). Только для начальной инициализации, затем заменятся метками и их координатами
            center: [55.800798, 37.554765],
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 14,
            // элементы управления (ничего, если оставить пустым)
            // https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/map.control.Manager.html#map.control.Manager__param-controls
            controls: ['zoomControl'],

        })
        // Увеличение карты при скроле
        mapContacts.behaviors.disable('scrollZoom');
        // Добавление меток (массив обьектов "marks" передается в шаблоне)
        if(typeof marks != 'undefined') {
            createMarks(marks, mapContacts)
        }
    }

    const serviceMap = document.getElementById('service-map')

    if(serviceMap) {
        // Создание карты.
        const mapContacts = new ymaps.Map(serviceMap, {
            // Координаты центра карты (широта, долгота). Только для начальной инициализации, затем заменятся метками и их координатами
            center: [55.753215, 37.622504],
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 10,
            // элементы управления (ничего, если оставить пустым)
            // https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/map.control.Manager.html#map.control.Manager__param-controls
            controls: ['zoomControl'],

        })
        // Увеличение карты при скроле
        mapContacts.behaviors.disable('scrollZoom');
        // Добавление меток (массив обьектов "marks" передается в шаблоне)
        if(typeof marks != 'undefined') {
            createMarks(marks, mapContacts)
        }
        // увеличение карты при клике
        mapContacts.geoObjects.events.add("click", function (e) {
            let coords = e.get("target").geometry.getCoordinates();
            mapContacts.setCenter(coords, 15);
        });
    }
}

// создание меток из массива
function createMarks(arr, map) {
    if(arr.length > 0) {
        arr.forEach(el => {
            map.geoObjects.add(mark(el))
        });
    } else {
        throw new Error('Marks array empty');
    }
}
// обьект создания метки
function mark(el) {
    return new ymaps.Placemark(el.coord, {
        hintContent: el.name || '',
        balloonContent: el.text || '',
    }, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: el.image || '/static/images/marker.svg',
        // Размеры метки.
        iconImageSize: el.size || [30, 42],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-5, -38]
    })
}
