export function filterParamGet(selector) {
    const btns = document.querySelectorAll(selector)
    if(btns.length > 0) [...btns].map(btn => go(btn))

    const filter = document.querySelector('[data-brand-filter]')
    if(filter) addListener(filter)

    let url = new URL(window.location).searchParams

    function addListener(filter) {
        const checkboxArr = filter.querySelectorAll('input[type="checkbox"]')

        filter.addEventListener('click', (event) => {
            if ([...checkboxArr].find(el => el == event.target)) {
                if(event.target.checked) {
                    url.append('brand[]', event.target.value)
                    // console.log(url.toString());
                } else {
                    url = new URLSearchParams(decodeURI(url.toString()).replace('brand[]=' + event.target.value, ''))
                    // console.log(url.toString());
                }
                event.stopPropagation();
                return false;
            }
        })
    }


    function go(btn) {
        btn.addEventListener('click', () => {
            const newPath = window.location.origin + window.location.pathname + '?' + url.toString()
            if(window.location.href != newPath) window.location.href = newPath
            url = null
        })
    }
}
