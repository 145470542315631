export function liveSort(search, box) {
    const filterSearch = document.querySelector(`${search} input`);
    const filterBox = document.querySelector(`${box}`);
    if (filterSearch && filterBox) {
        const filterSityName = filterBox.querySelectorAll(`label`);
        const filterEmpty = filterBox.querySelector(".filter__empty");

        // скрыть предупреждение
        filterEmpty.style.display = "none";
        // отслеживание ввода
        filterSearch.addEventListener("input", () => {
            let value = filterSearch.value.toLowerCase();
            let arr = [];
            //
            filterSityName.forEach((el) => {
                filterEmpty.style.display = "none";
                // если инпут пустой показать все
                if (value.length === 0) {
                    el.style.display = "";
                    // если инпут не пустой скрыть все и ...
                } else if (value.length > 0) {
                    el.style.display = "none";
                }
                //
                let sity = el.querySelector("input").value.toLowerCase();
                if (sity.indexOf(value) > -1) {
                    arr.push(sity.indexOf(value) > -1);
                    // ... показать совпадения
                    el.style.display = "";
                    filterEmpty.style.display = "none";
                } else if (arr.length === 0) {
                    filterEmpty.style.display = "";
                }
            });
        });
    }
}
