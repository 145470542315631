export function showHide(fInput, fResult, lock = false) {
    // обрати внимание на точки
    const filterBtn = document.querySelector(`.${fInput}`);
    const filterResult = document.querySelector(`.${fResult}`);

    if (filterBtn && filterResult) {
        const filterItem = filterResult.querySelectorAll("input");
        // Вывод значения в "строку поиска"
        if (lock !== true) {
            filterItem.forEach((el) => {
                let input = filterBtn.querySelector("input");
                // Первый город выбран по дефолту
                input.value = filterItem[0].value;
                el.addEventListener("click", () => {
                    if (input.value === el.value) {
                        return;
                    } else {
                        input.value = el.value;
                    }
                    close();
                });
            });
        }

        // Открытие \ закрытие выпадающего списка
        document.addEventListener("click", (e) => {
            if (e.target.closest(`.${fInput}`)) {
                if (
                    !e.target
                        .closest(`.${fInput}`)
                        .classList.contains("filter__search_active")
                ) {
                    open();
                } else {
                    close();
                }
            } else {
                if (e.target.closest(".filter__result")) {
                    return;
                } else {
                    close();
                }
            }
        });
    }

    // Закрывающая функция
    function close() {
        if (!filterBtn.classList.contains("filter__search_active")) {
            return;
        } else {
            filterBtn.classList.remove(`filter__search_active`);
            filterResult.classList.remove(`filter__result_active`);
        }
    }
    // Открывающая функция
    function open() {
        filterBtn.classList.add(`filter__search_active`);
        filterResult.classList.add(`filter__result_active`);
    }
}
